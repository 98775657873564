export default {
    title:'website name',
    h_title: {
        index: 'index',
        login: 'login',
        zhuce: 'register'
    },
    common: {
        body_title: 'Please use landscape view',
        banquan: 'Copyright 2022 © V5LINK',
        congriqi: 'from date',
        daoriqi: 'to date',
        fenye: 'A total of {zhi} pages, {gong} results',
        chuangjianriqi: 'Date created',
        zhuangtai: 'state',
        zhonglei: 'type',
        youxi: 'game',
        shue: 'amount',
        shujuweikong: 'The data in the table is empty',
        shangye: 'last page',
        xiaye: 'next page',
        queren: 'confirm',
        quxiao: 'Cancel',
        xuanzenianyueri: 'Select year month day',
        leixing: 'type',
        qingxuanze: '--please choose--',
        leixing_list: ['Deposit','Withdrawal','Betting','Rebate','Quota Conversion'],
        qingshuru: 'please enter',
        qingxuanze_alt: 'please choose',
        fasong:"send",
        fuzhichenggong: 'Copy successfully!',
        queding: 'Sure',
        baobiao_status: ['','to be reviewed','success','rejected'],
        baobiao_status_beiyongjin: ['to be reviewed','success','rejected','Repayment'],
        touzhu:['play', 'bet', 'winner'],
        beiyongjin_list:['Borrowing record','Repayment record'],
        xianshangrenshu: 'number of people online',
        dailizhongxin_list: ['Commission distribution', 'member deposit', 'member withdrawal', 'member', 'member win or lose'],
        zhenshixingming: 'actual name',
        jinebunengdayuyue: 'Amount cannot be greater than balance',
        shurujine: 'Enter amount',
        dangqianyuee: 'current balance',
        meiyouxiayiye: 'No next page',
        meiyoushangyiye: 'No previous page',
        jizhumima:'Remember',
        fuzhi: 'copy',
        level_alt: 'The current level of VIP{level} can receive daily benefits {integral} points',
		liushui_alt: 'total bet number {liushui}',
        lingquchenggong: 'Received successfully'
    },
    login: {
        username_alt: 'please enter account',
        password_alt: 'Please enter password',
    },
    xgmima: {
        yuanshimima: 'original password',
        xinmima: 'new password',
        querenxinmima: 'Confirm the new password',
        shoujihaoyouwu: 'There is a problem with the mobile phone number, it may not be bound to the mobile phone',
		qbdsj:'Please bind your phone'
    },
    chongzhi: {
        chongzhijine: 'Recharge amount',
        shoukuanzhanghao: 'account',
        shoukuanzhanghuming: 'Accounts receivable',
        shoukuanerweima: 'Receipt QR code',
        chongzhidizhi: 'Deposit address (TRC)',
        alert: 'Confirm that the funds have been transferred to the designated account, and apply for higher points?',
        fukuanxingming:'Payment Names'
    },
    tixian: {
        xingming: 'Name',
        tixianjine: 'Amount',
        tikuanmima: 'Pass',
        tixianguandao: 'Pipeline',
        shengyumaliang: 'Remaining',
        jinedayu0: 'The amount must be greater than 0',
        yinhang: ['Fast speed', 'USDT', 'ICBC', 'China Construction Bank', 'Agricultural Bank of China', 'Bank of China', 'China Bank of Communications', 'China Merchants Bank', 'Guangfa Bank', 'Ping An Bank', 'Shanghai Pudong Development Bank', 'Minsheng Bank', 'Postal Savings Bank of China', 'Hua Xia Bank', 'Shenzhen Development Bank', 'China CITIC Bank', 'Industrial Bank', 'Everbright Bank', 'Standard Chartered Bank' ,'HSBC Bank','ZTE Bank','Hang Seng Bank','Innovation Bank']
    },
    zhuce: {
        zhanghao: 'account',
        mima: 'password',
        mimaxiaoyuliuwei: 'Password length cannot be less than 6 characters',
        mimabuyizhi: 'Password does not match',
        qingzaicishurumima: 'Please enter the password again',
        qukuanmima: 'Withdrawals password',
        shoujihao: 'Phone',
        yanzhema: 'code'
    },
    daili: {
        tuiguanglianjie: 'sponsored links'
    },
    beiyongjind: {
        xuanzeyouxi: 'choose game',
        shenqingjine: 'Application Amount',
        keshenqinedu: 'Available quota',
        err_alt: 'The amount applied for is greater than the amount that can be applied for'
    },
	fanshui: {
	    dlqje: 'Amount to be collected',
		tishi:'Self code shuffling, real-time settlement, please try again after the bet meets the standard'
	}
}
