<template>
  <div id="app">
    <keep-alive class="show">
      <!--        <NavBar></NavBar>-->
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view class="show" v-if="!$route.meta.keepAlive"></router-view>

    <section class="hide" style="display: none;">
      {{$t('common.body_title')}}
    </section>
  </div>

</template>
<script>
  import $ from 'jquery'
  export default {
    name: "name",
    mounted() {
      let config = this.$store.state.config
      if (config == ''){
        this.$router.push('/login')
      }
    },
    mounted() {

      this.init();

    },
    methods: {

      init() {
        this.forceLandscapeScreenHandle();

        // 这里监控
        this.onWindowSizeChanged();
      },

      forceLandscapeScreenHandle() {
        const body = document.getElementsByTagName('body')[0];
        const html = document.getElementsByTagName('html')[0];
        const width = html.clientWidth;
        const height = html.clientHeight;
        const max = width > height ? width : height;
        const min = width > height ? height : width;
        body.style.width = max + "px";
        body.style.height = min + "px";
        if(width < height){
          document.getElementsByTagName('html')[0].style.fontSize = (height - width) * .2 +'px';
          document.getElementsByTagName('body')[0].style.backgroundSize =  'auto';
        }
        else{
          document.getElementsByTagName('body')[0].style.backgroundSize =  'cover';
        }
      },

      onWindowSizeChanged() {
        window.addEventListener("resize", this.forceLandscapeScreenHandle)
      }
    },

    // beforeDestroy 废弃，使用 beforeUnmount
    beforeUnmount() {

    }

  }
</script>
<style lang="less">
  @import url('~path_style/resize.less');
  @import url('~path_style/resize.css');
  @import 'vant/lib/index.less';
  body{
    background: url("~path_img/bg.png") center no-repeat;
    background-size: cover;
  }
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  nav {
    padding: 30px;
    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }
  .hide{
    color: #fff;
    text-align: center;
    display: block;
  }
</style>
<style>
  .van-toast{
    width: 150px;
    font-size: .1rem;
  }
  .van-toast{
    /*background-color: rgba(245,52,0,.8);*/
  }
  body {
    /*让 body 初始 width 和 height 就 等于 页面可视区域的 宽高*/
    /*position: absolute;*/
    /*left: 0;*/
    /*top: 0;*/
    /*width: 100%;*/
    /*height: 100%;*/

  }

  @media screen and (orientation: portrait) {
    /*竖屏样式*/
    .mian {
      transform-origin: 0 0;
      transform: rotateZ(90deg) translateY(-100%);
    }
  }


</style>
