import {Toast} from "vant"
import https from '@/api/https'

/*
* 加载动画
* */
export function toast_loading(){
    Toast.loading({
        message: 'loading...',
        forbidClick: true,
        duration: 0
    });
}
/*
* 关闭toast
* */
export function toast_clear() {
    Toast.clear();
}
/*
* 获取时间 年月日时分秒
* */
export function getNowFormatDate(type) {//获取当前时间
    var date = new Date();
    var seperator1 = "-";
    var seperator2 = ":";
    var month = date.getMonth() + 1<10? "0"+(date.getMonth() + 1):date.getMonth() + 1;
    var minute = date.getMinutes()<10?"0"+date.getMinutes() : date.getMinutes()
    var strDate = date.getDate()<10? "0" + date.getDate():date.getDate();
    var currentdate = '';
    if(type == 1){
        currentdate = date.getFullYear() + seperator1  + month  + seperator1  + strDate
            + " "  + date.getHours()  + seperator2  + minute
            + seperator2 + date.getSeconds();
    }
    else{
        currentdate = date.getHours()  + seperator2  + minute;
    }
    return currentdate;
}
/*
*返回加N天后的日期
* */
export function GetDateStr(AddDayCount) {
    var dd = new Date();
    dd.setDate(dd.getDate()+AddDayCount);//获取AddDayCount天后的日期
    var y = dd.getFullYear();
    var m = (dd.getMonth()+1)<10?"0"+(dd.getMonth()+1):(dd.getMonth()+1);//获取当前月份的日期，不足10补0
    var d = dd.getDate()<10?"0"+dd.getDate():dd.getDate();//获取当前几号，不足10补0
    return y+"-"+m+"-"+d;
}
/*
* js 日期时间的格式化
* */
export function dateFormat(fmt, date) {
    let ret;
    const opt = {
        "Y+": date.getFullYear().toString(),        // 年
        "m+": (date.getMonth() + 1).toString(),     // 月
        "d+": date.getDate().toString(),            // 日
        "H+": date.getHours().toString(),           // 时
        "M+": date.getMinutes().toString(),         // 分
        "S+": date.getSeconds().toString()          // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
            fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
        };
    };
    return fmt;
}
export function handleLocalStorage(method, key, value) {
    switch (method) {
        case 'get' : {
            let temp = window.localStorage.getItem(key);
            if (temp) {
                return temp
            } else {
                return false
            }
        }
        case 'set' : {
            window.localStorage.setItem(key, value);
            break
        }
        case 'remove': {
            window.localStorage.removeItem(key);
            break
        }
        default : {
            return false
        }
    }
}
export function getUrlParam(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
    var r = window.location.search.substr(1).match(reg);  //匹配目标参数
    //  if (r != null) return unescape(r[2]); return null; //返回参数值
    if (r != null) return decodeURI(r[2]); return null; //返回参数值
}

export default {
    toast_loading,
    toast_clear,
    getNowFormatDate,
    GetDateStr,
    dateFormat,
    handleLocalStorage,
    getUrlParam
}
