export default {
    title:'网站名称',
    h_title: {
        index: '首页',
        login: '登录',
        zhuce: '注册'
    },
    common: {
        body_title: '请使用横屏浏览',
        banquan: 'Copyright 2022 © V5LINK',
        congriqi: '从日期',
        daoriqi: '到日期',
        fenye: '共 {zhi} 页, {gong} 条结果',
        chuangjianriqi: '创建日期',
        zhuangtai: '状态',
        zhonglei: '种类',
        youxi: '游戏',
        shue: '数额',
        shujuweikong: '表中数据为空',
        shangye: '上页',
        xiaye: '下页',
        queren: '确认',
        quxiao: '取消',
        xuanzenianyueri: '选择年月日',
        leixing: '类型',
        qingxuanze: '--请选择--',
        leixing_list: ['存款','提现','投注','返水','额度转换'],
        qingshuru: '请输入',
        qingxuanze_alt: '请选择',
        fasong:"发送",
        fuzhichenggong: '复制成功!',
        queding: '确定',
        baobiao_status: ['','待审核','成功','拒绝'],
        baobiao_status_beiyongjin: ['待审核','成功','拒绝','已还款'],
        touzhu:['玩法','投注','中奖金'],
        beiyongjin_list:['借款记录','还款记录'],
        xianshangrenshu: '线上人数',
        dailizhongxin_list: ['佣金发放','会员存款','会员提现','会员','会员输赢'],
        zhenshixingming: '真实姓名',
        jinebunengdayuyue: '金额不能大于余额',
        shurujine: '输入金额',
        dangqianyuee: '当前余额',
        meiyouxiayiye: '没有下一页',
        meiyoushangyiye: '没有上一页',
        jizhumima:'记住密码',
        fuzhi: '复制',
        level_alt: '当前等级VIP{level}可领取每日福利{integral}分',
        liushui_alt: '当前总流水{liushui}',
        lingquchenggong: '领取成功'
    },
    login: {
        username_alt: '请输入账号',
        password_alt: '请输入密码',
    },
    xgmima: {
        yuanshimima: '原始密码',
        xinmima: '新密码',
        querenxinmima: '确认新密码',
        shoujihaoyouwu: '手机号有问题，可能没有绑定手机',
		qbdsj:'请绑定手机'
    },
    chongzhi: {
        chongzhijine: '充值金额',
        shoukuanzhanghao: '收款账号',
        shoukuanzhanghuming: '收款账户名',
        shoukuanerweima: '收款二维码',
        chongzhidizhi: '充值地址(TRC)',
        alert: '确认已经转帐到指定帐户,申请上分？',
        fukuanxingming:'付款姓名'
    },
    tixian: {
        xingming: '姓名',
        tixianjine: '提款金额',
        tikuanmima: '提款密码',
        tixianguandao: '提现管道',
        shengyumaliang: '剩餘碼量',
        jinedayu0: '金额必须大于0',
        yinhang: ['转速快','USDT','中国工商银行','中国建设银行','中国农业银行','中国银行','中国交通银行','招商银行','广发银行','平安银行','浦发银行','民生银行','中国邮政储蓄银行','华夏银行','深圳发展银行','中信银行','兴业银行','光大银行','渣打银行','汇丰银行','中兴银行','恒生银行','创新银行']
    },
    zhuce: {
        zhanghao: '账号',
        mima: '密码',
        mimaxiaoyuliuwei: '密码长度不能小于6位',
        mimabuyizhi: '密码不一致',
        qingzaicishurumima: '请再次输入密码',
        qukuanmima: '取款密码',
        shoujihao: '手机号',
        yanzhema: '验证码'
    },
    daili: {
        tuiguanglianjie: '推广链接'
    },
    beiyongjind: {
        xuanzeyouxi: '选择游戏',
        shenqingjine: '申請金額',
        keshenqinedu: '可申請額度',
        err_alt: '申请金额大于可申请额度'
    },
	fanshui: {
	    dlqje: '待领取金额',
		tishi:'自主洗码,实时结算,请投注达标后再试'
	}
}
