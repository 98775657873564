export default {
    title:'網站名稱',
    h_title: {
        index: '首頁',
        login: '登錄',
        zhuce: '註冊'
    },
    common: {
        body_title: '請使用橫屏瀏覽',
        banquan: 'Copyright 2022 © V5LINK',
        congriqi: '從日期',
        daoriqi: '到日期',
        fenye: '共 {zhi} 頁, {gong} 條結果',
        chuangjianriqi: '創建日期',
        zhuangtai: '狀態',
        zhonglei: '種類',
        youxi: '遊戲',
        shue: '數額',
        shujuweikong: '表中數據為空',
        shangye: '上頁',
        xiaye: '下頁',
        queren: '確認',
        quxiao: '取消',
        xuanzenianyueri: '選擇年月日',
        leixing: '類型',
        qingxuanze: '--請選擇--',
        leixing_list: ['存款','提現','投註','返水','額度轉換'],
        qingshuru: '請輸入',
        qingxuanze_alt: '請選擇',
        fasong:"發送",
        fuzhichenggong: '復製成功!',
        queding: '確定',
        baobiao_status: ['','待審核','成功','拒絕'],
        baobiao_status_beiyongjin: ['待審核','成功','拒絕','已還款'],
        touzhu:['玩法','投註','中獎金'],
        beiyongjin_list:['借款記錄','還款記錄'],
        xianshangrenshu: '線上人數',
        dailizhongxin_list: ['傭金發放','會員存款','會員提現','會員','會員輸贏'],
        zhenshixingming: '真實姓名',
        jinebunengdayuyue: '金額不能大於余額',
        shurujine: '輸入金額',
        dangqianyuee: '當前余額',
        meiyouxiayiye: '沒有下一頁',
        meiyoushangyiye: '沒有上一頁',
        jizhumima:'記住密碼',
        fuzhi: '復製',
        level_alt: '當前等級VIP{level}可領取每日福利{integral}分',
		liushui_alt: '当前总流水{liushui}',
        lingquchenggong: '領取成功'
    },
    login: {
        username_alt: '請輸入賬號',
        password_alt: '請輸入密碼',
    },
    xgmima: {
        yuanshimima: '原始密碼',
        xinmima: '新密碼',
        querenxinmima: '確認新密碼',
        shoujihaoyouwu: '手機號有問題，可能沒有綁定手機',
		qbdsj:'請綁定手機'
    },
    chongzhi: {
        chongzhijine: '充值金額',
        shoukuanzhanghao: '收款賬號',
        shoukuanzhanghuming: '收款賬戶名',
        shoukuanerweima: '收款二維碼',
        chongzhidizhi: '充值地址(TRC)',
        alert: '確認已經轉帳到指定帳戶,申請上分？',
        fukuanxingming:'付款姓名'
    },
    tixian: {
        xingming: '姓名',
        tixianjine: '提款金額',
        tikuanmima: '提款密碼',
        tixianguandao: '提現管道',
        shengyumaliang: '剩餘碼量',
        jinedayu0: '金額必須大於0',
        yinhang: ['轉速快','USDT','中國工商銀行','中國建設銀行','中國農業銀行','中國銀行','中國交通銀行','招商銀行','廣發銀行','平安銀行','浦發銀行','民生銀行','中國郵政儲蓄銀行','華夏銀行','深圳發展銀行','中信銀行','興業銀行','光大銀行','渣打銀行','匯豐銀行','中興銀行','恒生銀行','創新銀行']
    },
    zhuce: {
        zhanghao: '賬號',
        mima: '密碼',
        mimaxiaoyuliuwei: '密碼長度不能小於6位',
        mimabuyizhi: '密碼不一致',
        qingzaicishurumima: '請再次輸入密碼',
        qukuanmima: '取款密碼',
        shoujihao: '手機號',
        yanzhema: '驗證碼'
    },
    daili: {
        tuiguanglianjie: '推廣鏈接'
    },
    beiyongjind: {
        xuanzeyouxi: '选择游戏',
        shenqingjine: '申請金額',
        keshenqinedu: '可申請額度',
        err_alt: '申请金额大于可申请额度'
    },
	fanshui: {
	    dlqje: '待領取金額',
		tishi:'自主洗碼，實时結算，請投注達標後再試'
	}
}
