import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/index',
    name: 'index',
    meta: {
      title: 'h_title.index',
      keepAlive: false
    },
    component: () => import('@/views/index.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: 'h_title.login',
      keepAlive: false
    },
    component: () => import('@/views/login.vue')
  },
  {
    path: '/zhuce',
    name: 'zhuce',
    meta: {
      title: 'h_title.zhuce',
      keepAlive: false
    },
    component: () => import('@/views/zhuce.vue')
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) { // 解决vue页面之间跳转，页面不是在顶部的问题
    return {x: 0, y: 0}
  }
})
export default router
